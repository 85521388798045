<template>
  <div class="body">
    <heads :more="showMore" :msg="title"></heads>
    <div class="search" @click="showCategory">
      <div class="left">
        <img class="search_icon" src="@/assets/search_icon.png" alt="">
        <span class="search_txt">搜索</span>
      </div>
      <div class="right">
        <img class="cate_icon" src="@/assets/tupianku_category.png" alt="">
        <span class="category">分类</span>
      </div>
    </div>
    <van-swipe class="my-swipe" indicator-color="white">
      <van-swipe-item v-for="(item,index) in banners" :key="index" class="swipe-item">
        <img class="thumb" :src="item.pic" alt @click="goBannerUrl(item.tid)">
        <div class="banner_title_box">
          <div class="banner_title">
            <p class="text">{{ item.title }}</p>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="content">
      <div class="title">
        <b>每日精选</b>
      </div>
      <div class="lists">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoadDatas">
          <div v-for="(obj,idx) in lists" class="item" @click="goDetail(obj)">
            <p class="thread_title">{{ obj.title }}</p>
            <img class="thumb" :src="obj.pic" alt="">
            <div class="info">
              <div class="numbers">
                <img class="viewIcon" src="@/assets/tupianku_view.png" alt="">
                <span class="viewNumber">{{ obj.view_num }}人浏览</span>
                <img class="replyIcon" src="@/assets/tupianku_reply.png" alt="">
                <span class="replyNumber">{{ obj.reply_num }}人跟帖</span>
              </div>
              <div class="user">
                <img class="avatar" :src="obj.avatar" alt="">
                <span class="uname">{{ obj.username }}</span>
              </div>
            </div>
            <p class="texts">{{ obj.content }}</p>
          </div>
        </van-list>
      </div>
    </div>
    <!-- 弹框 -->
    <van-popup v-model="showPopview" position="right" class="popView">
      <div class="pop">
        <div class="topSearch">
          <div class="gray">
            <div class="left" @click="clickDown">
              <span class="tit_span">{{ selectFName }}</span>
              <img class="tit_icpn" src="@/assets/tupianku_select_down.png" alt="">
              <span class="v_line"></span>
            </div>
            <div class="right">
              <input v-model="searchValue" class="inputs" placeholder="搜索内容" style="background-color:#f4f4f4;" @keyup.enter="getData" @blur="blurFun" @focus="focusFun">
              <img src="" alt="">
              <img class="search_icon" src="@/assets/search_icon.png" alt="" @click="getData">
            </div>
          </div>
        </div>
        <div v-show="selectDown" class="popDowb">
          <div v-for="(cat) in fanmes" :class="cat.title === selectFName ? 'grayBg aItem':'aItem'" @click="selectOneFname(cat)">
            <span>{{ cat.title }}</span>
          </div>
        </div>
        <div v-for="(obj1,idx1) in gnames" class="pLists" @click="showCategoryDetail(obj1)">
          <div class="left">
            <img class="gicon" :src="obj1.thumb" alt="">
            <span class="gname">{{ obj1.name }}</span>
            <span class="number">{{ obj1.num }}</span>
          </div>
          <div class="arrow">
            <img src="@/assets/tupianku_arrow.png" alt="">
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { gitPhoto } from '@/api/infohome'
import heads from '@/components/common/heads.vue'
import tool from '@/utils/tools.js'
import { searchPhotos } from '@/api/search.js'
export default {
  components: {
    heads
  },
  data() {
    return {
      title: '图片库',
      showMore: true,
      banners: [],
      lists: [],
      loading: false,
      finished: false,
      page: 1,
      count: 0,
      // 接口limit不管用好像
      limit: 10,
      selectFName: '全部',
      searchValue: '',
      // 弹框页面数据
      selectDown: false,
      showPopview: false,
      fanmes: [],
      gnames: [],
      loading1: false,
      finished1: false,
      page1: 1,
      count1: 0,
      limit1: 20
    }
  },
  created() {
    this.getListDatas()
  },
  methods: {
    // 轮播图点击事件
    goBannerUrl(tid) {
      window.location.href = `https://m.zhulong.com/bbs/postDetail?tid=${tid}`
    },
    // 列表点击事件
    goDetail(val) {
      window.location.href = val.url
    },
    // 获取数据
    getListDatas() {
      this.loading = true
      var params = {
        page: this.page,
        limit: this.limit
      }
      gitPhoto(params).then(res => {
        this.loading = false
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result
          const banners = result.bannerList || []
          if (this.banners.length < 1) {
            this.banners = banners
          }
          const list = result.list ? result.list : []
          this.lists = this.lists.concat(list)
          this.count = result.count
          if (this.lists.length < this.count) {
            this.page++
          } else {
            this.finished = true
          }
        }
      })
    },
    // 分页
    onLoadDatas() {
      this.getListDatas()
    },
    // 展示分类
    showCategory() {
      // 默认显示全部
      this.page1 = 1
      this.getPhotosLists()
      this.showPopview = true
    },
    // 获取图库分类
    getPhotosLists(fname, input) {
      this.loading1 = true
      const post = {
        page: this.page1,
        limit: this.limit1
      }
      if (fname && fname !== '全部') {
        post.fname = fname
      }
      if (input) {
        post.q = input
      }
      console.log(post)
      searchPhotos(post).then(res => {
        this.loading1 = false
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result
          const count = result.count
          const fname = result.fname ? result.fname : {}
          const gname = result.gname ? result.gname : {}
          // 分类
          if (this.page1 === 1) {
            this.fanmes = []
            this.fanmes.push({ title: '全部', number: 0, isAll: 1 })
            for (const i in fname) {
              this.fanmes.push({ title: i, number: fname[i], isAll: 0 })
            }
          }
          // 小组列表
          this.gnames = gname
        }
      })
    },
    // 去分类页
    showCategoryDetail(val) {
      var q = this.searchValue
      if (!q) {
        q = ''
      }
      this.$router.push({ path: '/tupianku/categoryList', query: { gname: val.name, q: q }})
    },
    getData() {
      this.getPhotosLists(this.selectFName, this.searchValue)
    },
    blurFun() {
    },
    focusFun() {
    },
    clickDown() {
      this.selectDown = !this.selectDown
    },
    selectOneFname(cat) {
      this.selectDown = false
      this.selectFName = cat.title
      this.getPhotosLists(this.selectFName, this.searchValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .left {
    width: calc(100% - 170px);
    height: 64px;
    margin: 0 30px;
    background-color: #f4f4f4;
    border-radius: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .search_icon {
      margin-left: 40px;
      width: 28px;
    }
    .search_txt {
      margin-left: 10px;
      font-family: PingFangSC-Regular;
      font-size: 26px;
      color: #999999;
    }
  }
  .right {
    width: 110px;
    padding-right: 30px;
    .cate_icon {
      width: 28px;
    }
    .category {
      margin-left: 10px;
      font-family: PingFangSC-Regular;
      font-size: 32px;
      color: #333333;
    }
  }
}
.my-swipe {
  .swipe-item {
    width: 100%;
    height: 450px;
    position: relative;
    .thumb {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .banner_title_box {
      background-image: linear-gradient(to bottom, rgba(51,51,51,0), rgba(51,51,51,0.75));
      position: absolute;
      width: 100%;
      height: 160px;
      bottom: 0px;
    }
    .banner_title {
      margin-top: 50px;
      // background-color: darkgoldenrod;
      padding-left: 30px;
      p {
        width: 100%;
        height: 50px;
        color: #fff;
        font-size: 36px;
        line-height: 50px;
        text-align: left;
      }
    }
  }
}
.content {
  background-color: #f5f5f5;
  padding-top: 30px;
  .title {
    padding-top: 1px;
    width: calc(100% - 60px);
    height: 45px;
    margin: 0px 30px 30px;
    border-left: 5px solid red;
    line-height: 45px;
    position: relative;
    background-color: #f5f5f5;
    b {
      font-size: 32px;
      margin-left: 15px;
    }
  }
  .lists {
    padding: 0 20px;
    width: calc(100% - 40px);
    background-color: #f5f5f5;
    .item {
      border-radius: 16px;
      padding: 20px 20px 40px;
      width: calc(100% - 40px);
      background-color: #fff;
      margin-bottom: 20px;
      .thread_title {
        font-family: PingFangSC-Regular;
        font-size: 32px;
        color: #333333;
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .thumb {
        margin-top: 20px;
        display: block;
        width: 100%;
        height: 320px;
        object-fit: cover;
      }
      .info {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .numbers {
          display: flex;
          flex-direction: row;
          align-items: center;
          .viewIcon {
            width: 30px;
          }
          .viewNumber {
            margin-left: 8px;
            font-family: PingFangSC-Regular;
            font-size: 24px;
            color: #999999;
          }
          .replyIcon {
            margin-left: 32px;
            width: 30px;
          }
          .replyNumber {
            margin-left: 8px;
            font-family: PingFangSC-Regular;
            font-size: 24px;
            color: #999999;
          }
        }
        .user {
          display: flex;
          flex-direction: row;
          align-items: center;
          .avatar {
            width: 30px;
            display: block;
            border-radius: 15px;
          }
          .uname {
            margin-left: 10px;
            font-family: PingFangSC-Medium;
            font-size: 24px;
            color: #999999;
          }
        }
      }
      .texts {
        margin-top: 20px;
        font-family: PingFangSC-Regular;
        font-size: 26px;
        color: #999999;
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 376px;
  text-align: center;
}
/deep/.van-image__img {
  border-radius: 10px;
}
.popView {
  width: calc(100% - 110px);
  height: 100%;
}

.pop {
  width: calc(100% - 60px);
  padding: 0 30px;
  .topSearch {
    width: 100%;
    height: 88px;
    padding: 12px 0;
    .gray {
      margin-top: 12px;
      height: 64px;
      border-radius: 32px;
      background-color: #f4f4f4;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .left {
        width: 220px;
        height: 100%;
        display: flex;
        align-items: center;
        .tit_span {
          display: inline-block;
          text-align: center;
          line-height: 40px;
          width: 180px;
          height: 40px;
          // border-right: 1px solid #e0e0e0;
          font-family: PingFangSC-Medium;
          font-size: 30px;
          color: #333333;
          word-break: break-all;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .v_line {
          margin-left: 20px;
          display: inline-block;
          text-align: center;
          width: 1px;
          height: 40px;
          background-color: #e0e0e0;
        }
        .tit_icpn {
          width: 24px;
        }
      }
      .right {
        // width: 320px;
        height: 100%;
        float: right;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: right;
        .search_icon {
          margin-left: 10px;
          margin-right: 26px;
          width: 28px;
        }
      }
    }
  }
  .pLists {
    width: 100%;
    height: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .gicon {
        width: 72px;
        border-radius: 36px;
        display: block;
      }
      .gname {
        margin-left: 20px;
        font-family: PingFangSC-Regular;
        font-size: 30px;
        color: #333333;
      }
      .number {
        margin-left: 20px;
        font-family: PingFangSC-Regular;
        font-size: 30px;
        color: #999999;
      }
    }
    .arrow {
      img {
        width: 30px;
      }
    }
  }
}
.popDowb {
  position: absolute;
  width: 220px;
  min-height: 320px;
  background-color: #fff;
  z-index: 2010;
  left: 50px;
  top: 90px;
  border: 0 solid #f4f4f4;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  .aItem {
    width: 100%;
    height: 80px;
    display: inline-block;
    line-height: 80px;
    text-align: center;
    font-family: PingFangSC-Regular;
    font-size: 30px;
    color: #333333;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .grayBg {
    width: 100%;
    background-color: #f4f4f4;
  }
}
</style>
